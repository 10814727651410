



















import {
  Component, Vue,
} from 'vue-property-decorator';
import { RequestSendViewModel }
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/change-bank-account/status/request-send-view-model';

@Component({ name: 'RequestSend' })
export default class RequestSend extends Vue {
  request_send_model = Vue.observable(
    new RequestSendViewModel(),
  );
}
